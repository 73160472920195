import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import Nav from "./elements/Nav";
import Rs from "./elements/Rs";
import Scene_1 from "./elements/Scene_1";
import Scene_2 from "./elements/Scene_2";
import Scene_3 from "./elements/Scene_3";
import Scene_4 from "./elements/Scene_4";

function App() {
  async function onInit() {
    let account = '0x0';
    if (window.ethereum) {
      await window.ethereum.enable();
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      account = accounts[0];
      setWalletAdress(account)
      //setBalance(balance)
      window.ethereum.on('accountsChanged', function (accounts) {
        // Time to reload your interface with accounts[0]!
        setWalletAdress(account)
      });
    } else {
      setWalletAdress(account)
    }
  }



  let [walletAdress, setWalletAdress] = useState();
  let [balance, setBalance] = useState();

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimingNftFree, setClaimingNftFree] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [feedbackFree, setFeedbackFree] = useState(`FREE MINT`);
  const [withdrawResult, setWithdrawResult] = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  const [mintAmount, setMintAmount] = useState(1);
  const [ownerOfContract, setOwnerOfContract] = useState();
  const [lastFreeMintPerWallet, setLastFreeMintPerWallet] = useState(0);
  const [daysSinceLastFreeMint, setDaysSinceLastFreeMint] = useState(0);
  const [hoursSinceLastFreeMint, setHoursSinceLastFreeMint] = useState(0);
  let lastMintSince = data.lastFreeMint;
  let nbMint = data.nbMint;
  let accountWallet = data.account;
  let ownerContract = data.owner;
  let isStarted = data.started;

  // let audio = new Audio("snakes/mp3/jazzSnake.mp3");
  const start = () => {
    // if (!isPlaying) {
    //   audio.play();
    //   setIsPlaying(true);
  }


  if (lastMintSince && lastMintSince != 0) {
    const currentDate = new Date();
    let difference = currentDate.getTime() - (lastMintSince * 1000);
    let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    let hoursDifference = Math.floor(difference / 1000 / 60 / 60);

    if (hoursDifference > 24) {
      hoursDifference = 24;
    }
    let nextMintInHours = 24 - hoursDifference


    if (nextMintInHours != hoursSinceLastFreeMint) {
      setHoursSinceLastFreeMint(nextMintInHours)
    }

  } else {
    if (hoursSinceLastFreeMint != 0) {
      setHoursSinceLastFreeMint(0)
    }

  }
  if (ownerContract != ownerOfContract) {
    setOwnerOfContract(ownerContract);
  }

  if (accountWallet != walletAdress) {
    setWalletAdress(accountWallet);
  }

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {

    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .snakeInYourMint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const freeMint = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = 0;
    let totalGasLimit = String(gasLimit * mintAmount);
    setFeedbackFree(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNftFree(true);
    blockchain.smartContract.methods
      .snakeInYourFreeMint()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedbackFree("Sorry, something went wrong please try again later.");
        setClaimingNftFree(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedbackFree(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNftFree(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = 0;
    let totalGasLimit = String(gasLimit * mintAmount);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setWithdrawResult("Sorry, something went wrong please try again later.");
      })
      .then((receipt) => {
        console.log(receipt);
        setWithdrawResult(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    if (newMintAmount > 10 - nbMint) {
      newMintAmount = 10 - nbMint;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    if (newMintAmount > 10 - nbMint) {
      newMintAmount = 10 - nbMint;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  // let vAudio = document.getElementById("myaudio");
  // let hasInit = false;
  // if (!hasInit) {
  //   hasInit = true;
  //   vAudio.play();
  // }

  useEffect(() => {
    getConfig();
    onInit();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  let accountParaph = 'Connect';
  if (data.account) {
    accountParaph = data.account.substr(0, 6) + "...";
  }
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  return (
    <>
      <div className="loader  d-flex align-items-center justify-content-center">
        {/* <div className="d-block"><img src={"img/uniques/" + getRandomInt(1, 10) + ".gif"} alt="snake" width="200px" height="200px" /></div><br /> */}

        <p className="text-center navbar-brand cssanimation leSnake sequence">
          LOADING ...
        </p>
      </div>
      <nav className="navbar fixed-top navbar-expand-lg">
        <div className="container p-0">
          <h1 className="text-center navbar-brand cssanimation leSnake sequence">
            Snakes In Your Wallet
          </h1>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#" id="home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" id="mint_now" onClick={(e) => {

                  // start();
                  dispatch(connect());
                  getData();
                }}>Mint</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" id="roadmap">Roadmap</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#" id="team">FAQ</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" id="rarity">Rarity</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" id="connect" onClick={(e) => {
                  // start();
                  dispatch(connect());
                  getData();
                }}>{accountParaph}</a>
                {/* <a className="nav-link" href="#" id="connect">Connect</a> */}
              </li>
              {Number(walletAdress) === Number(ownerOfContract) && Number(ownerOfContract) !== 0 ? (
                <li>
                  <a className="nav-link"
                    onClick={(e) => {
                      e.preventDefault();
                      withdraw();
                    }}
                  >Withdraw
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
          <div className="btn-shadow">
            <div className="group-btn-free-mint">
              <button className="btn-free-mint" id="btn-free-mint" onClick={(e) => {
                // start();
                dispatch(connect());
                getData();
              }}>FREE MINT</button>
              <span className="btn-free-mint-shadow">+ cost Gas Fee ETH</span>
            </div>
          </div>
        </div>
      </nav>

      <div>
        <div className="scroll_again">SssSsSsscroll</div>
        <div className="scroll_top">▲ THE END ▲</div>
      </div>
      <Rs />
      <Scene_1 />
      <section id="scene_two" className="pt-5 align-items-center text-center justify-content-center  scene-2 bg-orange">
        <Scene_2 />
        <div className="mint_block">
          <h2 className="mb-2">{data.totalSupply} / {CONFIG.MAX_SUPPLY}</h2>
          <a className="text-dark contract" target={"_blank"} href={CONFIG.SCAN_LINK}>
            Contract : {CONFIG.CONTRACT_ADDRESS}
          </a>
          <div className="group-btn-retro mb-4">
            <small> You have Minted : {nbMint} / 10 🐍</small>
          </div>


          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <p className="project-text color-black">
                The sale has ended.
              </p>
              <p className="project-text color-black">
                You can still find {CONFIG.NFT_NAME} on
              </p>
              <a className="project-text color-black" target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                {CONFIG.MARKETPLACE}
              </a>
            </>
          ) : (
            <>
              {isStarted ? (<>

                <div className="group-btn-retro">
                  <small>1 FREE MINT PER 24h</small>
                </div>

              </>) : null}
              {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                <>
                  <h6 className="project-heading color-black" style={{ fontSize: "1.2rem" }}>
                    Connect to the {CONFIG.NETWORK.NAME} network
                  </h6>
                  <button className="btn-free-mint-go"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                      start();
                    }}
                  >
                    Connexxxxxion 🐍
                  </button>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <p className="project-text color-black">
                        {blockchain.errorMsg}
                      </p>
                    </>
                  ) : null}
                </>
              ) : (

                <div className="text-center">
                  {nbMint != 10 && isStarted ? (<>
                    <div className="group-btn-free-mint-go">
                      {(hoursSinceLastFreeMint != 0) ? (
                        <>
                          <button className="btn-free-mint-go"
                            disabled={false}
                            onClick={(e) => {
                              e.preventDefault();
                              getData();
                            }}
                          >
                            Next free mint in less {hoursSinceLastFreeMint} h
                          </button>
                        </>) : (
                        <>
                          <button className="btn-free-mint-go" id="btn-free-mint-go"
                            disabled={claimingNftFree ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              freeMint();
                              getData();
                            }}
                          >
                            {claimingNftFree ? "MINTING..." : "MINT for free"}
                          </button>
                        </>)}
                    </div>
                    <div className="group-btn-retro mt-3">
                      If you want more, 1 {CONFIG.SYMBOL} cost {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL}<small> Excluding gas fees.</small>
                    </div>

                    <p className="project-text color-black mt-4">
                      {feedback}
                    </p>
                    <div className="mt-3">
                      <button className="btn btn-less"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </button>
                      <span className="nb-mint " >{mintAmount}</span>
                      <button className="btn btn-more"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </button>
                    </div>
                    <div className="mt-3">
                      <button className=" btn-mint-go"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "Mint for 0.007ETH"}
                      </button>
                    </div>
                  </>
                  ) :
                    <>
                      {!isStarted ? (<><h5 className="mt-4">MINT Coming SsssSsssoon 🐍🛎️</h5></>) : null}
                      <img src="img/uniques/1.gif" alt="snake" width="200px" height="200px" />
                    </>
                  }
                </div>
              )}
            </>
          )}
        </div>
      </section>
      <Scene_3 />
      <Scene_4 />
    </>
  );
}
console.log('Our Twitter : 🐍🛎️ https://twitter.com/SnakesIYWallet  🐍🛎️');
console.log('Our Opensea : 🐍🛎️ https://opensea.io/collection/snakes-in-your-wallet  🐍🛎️');
export default App;
