import React from 'react';

const Scene_3 = () => {
    return (
        <section className="pt-5 align-items-center justify-content-center bg-night" id="game">
            <div className="w-100 h-100 container text_faq">

            </div>
            <div className="scene_cavern">
                <div className="paragraph_cavern">
                    <div className="row">
                        <div className="col-0 col-lg-4" />
                        <div className="col-12 col-lg-8 p-5">
                            <p>
                                SIYW is an NFT collection of 10,000 unique, hand-drawn, pixel
                                art Snakes programmatically generated with 12 gif frames, over
                                140+ attributes and traits.
                            </p>
                            <p>10 Snakes are totally unique with their own traits.</p>
                            <p>
                                Each Snake is stored on the Ethereum blockchain as an ERC-721
                                token and hosted on IPFS. Owning a Snake will giving you
                                opportunities for giveaways, raffles, airdrops and extra
                                content.
                            </p>
                            You want see our uniques Snakes ? &nbsp;
                            <button id="uniques_snakes_btn" className="btn btn-retro btn-more">
                                Play game
                            </button>
                        </div>
                    </div>
                </div>
                <img className="prop_cavern" src="./img/story/cavern/l2_prop01.png" alt="prop" />
                <img className="prop_2_cavern" src="./img/story/cavern/l3_prop02.png" alt="prop" />
                <div className="cavern_scene_bg" />
                <img className="stones_cavern" src="./img/story/cavern/l4_stones.png" alt="stones" />
                <img className="crystal_cavern" src="./img/story/cavern/l5_crystals.png" alt="crystal" />
                <div className="ground_cavern">
                    <div className="slider-2">
                        <div className="slide-track-2">
                            <div className="slide">
                                <img src="img/snakes_gif/unique_1.gif" alt="unique 1" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_2.gif" alt="unique 2" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_3.gif" alt="unique 3" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_4.gif" alt="unique 4" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_5.gif" alt="unique 5" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_6.gif" alt="unique 6" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_7.gif" alt="unique 7" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_8.gif" alt="unique 8" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_9.gif" alt="uniqu 9" />
                            </div>
                            <div className="slide">
                                <img src="img/snakes_gif/unique_10.gif" alt="unique 10" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Scene_3;